import PropTypes from 'prop-types';
import { Component } from 'react';
import withFlattenedProps from './withFlattenedProps';

export default function withControlledValue(ControlledComponent) {
  class WithControlledValue extends Component {
    static propTypes = {
      initialValue: PropTypes.string,
      onChange: PropTypes.func,
    };

    static defaultProps = {
      initialValue: '',
      onChange: () => {},
    };

    constructor(props) {
      super(props);

      this.state = {
        value: props.initialValue,
      };
    }

    handleChange = (event, value) => {
      this.props.onChange(event, value);
      this.setState({ value });
    };

    render() {
      // Necessary because `initialValue` is not a legal DOM attribute of `input`
      // https://reactjs.org/warnings/unknown-prop.html
      //
      // eslint-disable-next-line no-unused-vars
      const { initialValue, ...otherProps } = this.props;

      return (
        <ControlledComponent
          {...otherProps}
          onChange={this.handleChange}
          value={this.state.value}
        />
      );
    }
  }

  return withFlattenedProps(WithControlledValue);
}

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { openOneTrustBannerOnInit, setOneTrustData } from '../actions/app';
import {
  logCategoryActionLabel,
  logClientError,
  logClientInfo,
} from '../actions/logging';
import oneTrust from '../consent/OneTrust';
import {
  selectGdprApplies,
  selectIsDiscord,
  selectOneTrust,
  selectTuneInUserSerial,
} from '../selectors/app';
import { selectUserDetails } from '../selectors/me';
import shouldShowPlayer from '../utils/playerStatus/shouldShowPlayer';
import useActions from './useActions';
import useOnMount from './useOnMount';

export default function useOneTrust({ isMobileBlockerFlow }) {
  const {
    shouldDialogOpen = false,
    openSettingsDialogOnInit: openDialogOnInit = false,
  } = useSelector(selectOneTrust);
  const gdprApplies = useSelector(selectGdprApplies) || false;
  const isPlayerVisible = useSelector(shouldShowPlayer) || false;
  // when the user is logged in, we want to use their id for the Data Subject ID
  const { id: userDetailsId } = useSelector(selectUserDetails) || {};
  const tuneInUserSerial = useSelector(selectTuneInUserSerial);
  const isDiscord = useSelector(selectIsDiscord);
  const dataSubjectId = userDetailsId || tuneInUserSerial || '';

  const actions = useActions({
    setOneTrustData,
    openOneTrustBannerOnInit,
    logClientError,
    logClientInfo,
    logCategoryActionLabel,
  });

  useOnMount(() => {
    oneTrust.init({
      dataSubjectId,
      actions,
      isMobileBlockerFlow,
      isDiscord,
    });
  });

  useEffect(() => {
    if (openDialogOnInit && !isMobileBlockerFlow) {
      oneTrust.init({
        openDialogOnInit,
        dataSubjectId,
        actions,
        isMobileBlockerFlow,
        isDiscord,
      });
    }
  }, [
    actions,
    dataSubjectId,
    isDiscord,
    isMobileBlockerFlow,
    openDialogOnInit,
  ]);

  useEffect(() => {
    if (shouldDialogOpen) {
      oneTrust.openSettingsDialog();
      oneTrust.flipFloatingButtonOnInfoDisplay();
      actions.setOneTrustData({ shouldDialogOpen: false });
    }
  }, [actions, shouldDialogOpen]);

  useEffect(() => {
    if (gdprApplies) {
      oneTrust.setDataSubjectId(dataSubjectId);
    }
  }, [dataSubjectId, gdprApplies]);

  useEffect(() => {
    oneTrust.handlePlayerVisibilityChange(isPlayerVisible);
  }, [isPlayerVisible]);
}

import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

export default function useActions(actions = {}) {
  const dispatch = useDispatch();
  const actionsRef = useRef(actions);

  return useMemo(
    () => bindActionCreators(actionsRef.current, dispatch),
    [dispatch],
  );
}

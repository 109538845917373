import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { trackSignUpPage } from 'src/common/actions/pageTracking';
import { selectUserDetails } from 'src/common/selectors/me';
import * as AuthActions from '../../actions/auth';
import { selectIsAuthenticated } from '../../selectors/auth';

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  userDetails: selectUserDetails(state),
  loginDetails: state.loginDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(
      {
        ...AuthActions,
        trackSignUpPage,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps);

export const reportingAdFormats = {
  AD_DISPLAY_FORMAT_UNSPECIFIED: 'AD_DISPLAY_FORMAT_UNSPECIFIED',
  AD_DISPLAY_FORMAT_300_250: 'AD_DISPLAY_FORMAT_300_250',
  AD_DISPLAY_FORMAT_728_90: 'AD_DISPLAY_FORMAT_728_90',
  AD_DISPLAY_FORMAT_320_50: 'AD_DISPLAY_FORMAT_320_50',
  AD_DISPLAY_FORMAT_970_250: 'AD_DISPLAY_FORMAT_970_250',
  AD_DISPLAY_FORMAT_300_600: 'AD_DISPLAY_FORMAT_300_600',
};

export const adFormatNameMap = {
  '300x250': reportingAdFormats.AD_DISPLAY_FORMAT_300_250,
  '728x90': reportingAdFormats.AD_DISPLAY_FORMAT_728_90,
  '320x50': reportingAdFormats.AD_DISPLAY_FORMAT_320_50,
  '970x250': reportingAdFormats.AD_DISPLAY_FORMAT_970_250,
  '300x600': reportingAdFormats.AD_DISPLAY_FORMAT_300_600,
};

import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PlayerActions from 'src/common/actions/player';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import {
  reportPlayerPauseClick,
  reportPlayerStopClick,
} from '../actions/reporting';
import { selectIsMobile } from '../selectors/app';
import {
  selectAutoPlayGuideItem,
  selectCanScrub,
  selectExternalHtmlPlayerUrl,
  selectIsMediaAdLoaded,
  selectPlayerStatus,
  selectTunedGuideId,
} from '../selectors/player';

export const syncedPlayerActions = {
  [playerStatuses.playing]: (actions, canScrub) => {
    if (canScrub) {
      actions.pause();
      actions.reportPlayerPauseClick();
      return;
    }

    actions.stop();
    actions.reportPlayerStopClick();
  },
  [playerStatuses.paused]: (actions) => actions.play(),
  [playerStatuses.stopped]: (actions, canScrub, guideItem, disablePreroll) =>
    actions.retune({ disablePreroll }),
  [playerStatuses.failed]: (actions, canScrub, guideItem, disablePreroll) =>
    actions.retune({ disablePreroll }),
  [playerStatuses.idle]: (actions, canScrub, guideItem, disablePreroll) => {
    if (guideItem) {
      return actions.tune({ guideItem, disablePreroll });
    }

    return noop;
  },
};

export function mapStateToProps(state) {
  return {
    playerStatus: selectPlayerStatus(state),
    canScrub: selectCanScrub(state),
    tunedGuideId: selectTunedGuideId(state),
    externalHtmlPlayerUrl: selectExternalHtmlPlayerUrl(state),
    isMobile: selectIsMobile(state),
    autoPlayGuideItem: selectAutoPlayGuideItem(state),
    isMediaAdLoaded: selectIsMediaAdLoaded(state),
  };
}

function mapDispatchToProps(dispatch) {
  const playerActions = {
    ...PlayerActions,
    reportPlayerStopClick,
    reportPlayerPauseClick,
  };

  return {
    playerActions: bindActionCreators(playerActions, dispatch),
  };
}

export default function connectWithPlayer(MyComponent) {
  /* eslint-disable react/prefer-stateless-function */
  class ConnectWithPlayer extends Component {
    static propTypes = {
      guideItem: PropTypes.object,
      playerStatus: PropTypes.string.isRequired,
      actions: PropTypes.object,
      isMobile: PropTypes.bool.isRequired,
      autoPlayGuideItem: PropTypes.object.isRequired,
      canScrub: PropTypes.bool,
      tunedGuideId: PropTypes.string,
      isMediaAdLoaded: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      autoPlayGuideItem: {},
    };

    render() {
      const { playerStatus, guideItem, tunedGuideId, autoPlayGuideItem } =
        this.props;
      const playGuideId = guideItem?.actions?.play?.guideId;
      const isPrimedForAutoPlay =
        playerStatus === playerStatuses.idle &&
        playGuideId === autoPlayGuideItem.guideId;
      const isSyncedWithPlayer = !!(
        playGuideId &&
        playGuideId === tunedGuideId &&
        // we still need to tune in the case when !isPrimedForAutoPlay which is why
        // it's not considered to be in sync with player
        !isPrimedForAutoPlay
      );
      const syncedPlayerStatus = isSyncedWithPlayer
        ? playerStatus
        : playerStatuses.idle;

      return (
        <MyComponent
          {...this.props}
          isSyncedWithPlayer={isSyncedWithPlayer}
          syncedPlayerStatus={syncedPlayerStatus}
          syncedPlayerAction={syncedPlayerActions[syncedPlayerStatus] || noop}
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ConnectWithPlayer);
}

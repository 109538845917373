import PropTypes from 'prop-types';
import { Component } from 'react';
import { mintSingleton } from '../../../client/mint';
import isServer from '../../utils/isServer';

export default function withAdScreenRequest(getScreenName) {
  return (BaseComponent) => {
    class WithScreenAdRequest extends Component {
      static propTypes = {
        match: PropTypes.object.isRequired,
      };

      constructor(props) {
        super(props);
        if (!isServer()) {
          mintSingleton.updateState('currentScreen', getScreenName(props));
        }
      }

      componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
          mintSingleton.updateState('currentScreen', getScreenName(this.props));
        }
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return WithScreenAdRequest;
  };
}

import { logClientError } from '../actions/logging';

export default function errorCatcher(cb) {
  function notify(err, store, action) {
    if (cb) {
      cb(err, store, action);
    }
  }

  return (store) => (next) => (action) => {
    /* eslint-disable consistent-return */
    if (action === null) {
      // we return null in some action creators due to conditionals
      // so we want to ignore these actions and not send them down the pipeline
      return;
    }

    if (action === undefined) {
      // This is a temporary log to make sure we're not unintentionally short-circuiting any action
      // creators. Once we're in the clear, we can migrate away from explicit-null to implicit-
      // undefined short-circuiting for action creators, as we would for any other function.
      store.dispatch(
        logClientError({
          message: 'Undefined action dispatched',
          context: { error: new Error('Undefined action dispatched') },
        }),
      );
    }

    try {
      notify(null, store, action);
      return next(action);
    } catch (err) {
      notify(err, store, action);
      return err;
    }
  };
}

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectGdprApplies,
  selectIsConsentReady,
  selectIsOptedOutOfTargeting,
} from '../selectors/app';

export default function useOnConsentReady(callback = () => {}) {
  const callbackRef = useRef(callback);
  const gdprApplies = useSelector(selectGdprApplies);
  const isConsentReady = useSelector(selectIsConsentReady);
  const isOptedOutOfTargeting = useSelector(selectIsOptedOutOfTargeting);
  const didCallCallbackRef = useRef(false);

  useEffect(() => {
    if (!didCallCallbackRef.current && isConsentReady) {
      didCallCallbackRef.current = true;
      callbackRef.current(gdprApplies, isOptedOutOfTargeting);
    }
  }, [isConsentReady, gdprApplies, isOptedOutOfTargeting]);
}

import { pick } from '@tunein/web-utils';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

export default function connectWithExperiments(experimentKeys) {
  function mapStateToProps(state) {
    return pick(state?.config?.autoupdate, experimentKeys);
  }

  if (!isEmpty(experimentKeys)) {
    return connect(mapStateToProps);
  }

  throw new Error(
    '`connectWithExperiments` must be passed an array of experiment keys',
  );
}

export const player = {
  category: 'player',
  actions: {
    error: 'error',
    reject: 'reject',
  },
};

export const playerClick = {
  category: 'playerClick',
  actions: {
    stop: 'stop',
    pause: 'pause',
  },
};

export const app = {
  category: 'app',
  actions: {
    error: 'error',
    performance: 'performance',
  },
  props: {
    discord: {
      types: {
        launch: 'launch',
        fatal: 'fatal',
      },
    },
  },
};

export const amazonVideoKeywords = {
  category: 'AmazonKeywords',
  actions: {
    error: 'Error',
  },
};

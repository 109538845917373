import interpolateComponents from 'interpolate-components';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import {
  DISCORD_GEORESTRICTION_GUIDE_ITEM_MULTIPLE_USERS,
  DISCORD_GEORESTRICTION_GUIDE_ITEM_SINGLE_USER,
} from '../constants/localizations/discord';
import { LINE_BREAK_INTERPOLATE } from '../constants/localizations/shared';
import { selectDiscordGeolocations } from '../selectors/app';

// Accepts georestriction metadata from a guide item and uses the geolocation metadata from the Discord activity state
// to count the number of users who aren't allowed to listen to the guide item.
export function useDiscordGeorestrictedUsersCountMessage(
  guideItem,
  removeLinebreaks = false,
) {
  const { regions, isExclusionList } =
    guideItem?.properties?.georestrictions || {};
  const discordGeolocations = useSelector(selectDiscordGeolocations);
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  if (!regions?.length || !discordGeolocations?.length) {
    return '';
  }

  const georestrictedUsersCount = discordGeolocations.reduce(
    (accumulator, { isoCode, memberCount } = {}) => {
      const hasIsoCode = regions.includes(isoCode);
      const shouldAddMemberCount =
        (isExclusionList && hasIsoCode) || (!isExclusionList && !hasIsoCode);

      return shouldAddMemberCount ? accumulator + memberCount : accumulator;
    },
    0,
  );

  if (!georestrictedUsersCount) {
    return '';
  }

  const localizedText = getLocalizedText(
    georestrictedUsersCount === 1
      ? DISCORD_GEORESTRICTION_GUIDE_ITEM_SINGLE_USER
      : DISCORD_GEORESTRICTION_GUIDE_ITEM_MULTIPLE_USERS,
  );

  return interpolateComponents({
    mixedString: removeLinebreaks
      ? localizedText.replaceAll(` ${LINE_BREAK_INTERPOLATE} `, ' ')
      : localizedText,
    components: {
      linebreak: <br />,
      usersCount: <span>{georestrictedUsersCount}</span>,
    },
  });
}

import PropTypes from 'prop-types';
import { Component } from 'react';
import EventListener from 'react-event-listener';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deriveAppBreakpointFromWindow } from '../../client/utils/breakpoints';
import { updateStoreBreakpoint } from '../actions/app';
import { WINDOW_RESIZE_DEBOUNCE_TIMEOUT } from '../constants/breakpoints';
import { selectBreakpoint, selectIsMobile } from '../selectors/app';
import isServer from '../utils/isServer';

export const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
  breakpoint: selectBreakpoint(state),
});

export function mapDispatchToProps(dispatch) {
  return {
    updateStoreBreakpoint: bindActionCreators(updateStoreBreakpoint, dispatch),
  };
}

export default function withBreakpoints(WrappedComponent) {
  class WithBreakPoints extends Component {
    static propTypes = {
      breakpoint: PropTypes.number,
      isMobile: PropTypes.bool.isRequired,
      updateStoreBreakpoint: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);

      if (!isServer()) {
        this.updateBreakpoint();
      }
    }

    componentWillUnmount() {
      clearTimeout(this.deferTimer);
    }

    updateBreakpoint() {
      const { breakpoint, updateStoreBreakpoint: updateBreakpoint } =
        this.props;
      const currentBreakpoint = deriveAppBreakpointFromWindow();

      if (currentBreakpoint !== breakpoint) {
        updateBreakpoint(currentBreakpoint);
      }
    }

    handleResize = () => {
      clearTimeout(this.deferTimer);

      this.deferTimer = setTimeout(() => {
        this.updateBreakpoint();
      }, WINDOW_RESIZE_DEBOUNCE_TIMEOUT);
    };

    render() {
      return (
        <EventListener target="window" onResize={this.handleResize}>
          <WrappedComponent {...this.props} />
        </EventListener>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithBreakPoints);
}

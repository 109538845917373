import {
  chooseLocationText,
  invalidCardDateErrorText,
  invalidCardNumberErrorText,
  invalidCvvErrorText,
  invalidPostalCodeErrorText,
  manualStateAndCountryText,
  mustBeLoggedIn,
} from 'src/common/utils/form/paymentValidation';
import keyMirror from 'src/common/utils/keyMirror';
import RecurlyValidationErrors from './recurlyValidationErrors';

const ValidationMessages = [
  chooseLocationText,
  manualStateAndCountryText,
  invalidPostalCodeErrorText,
  invalidCardNumberErrorText,
  invalidCvvErrorText,
  invalidCardDateErrorText,
  mustBeLoggedIn,
].concat(RecurlyValidationErrors);

export const validationMessagesMap = keyMirror(ValidationMessages);

export default ValidationMessages;

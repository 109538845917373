import PropTypes from 'prop-types';
import { Component } from 'react';
import LocationAndLocalizationProvider from '../providers/LocationAndLocalizationProvider';

export default function withContextProviders(TargetComponent) {
  class ComponentWithContextProviders extends Component {
    static propTypes = {
      locale: PropTypes.string.isRequired,
      localizations: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
    };

    render() {
      /* eslint-disable no-unused-vars,comma-dangle */
      const { locale, localizations, location, ...other } = this.props;
      /* eslint-enable no-unused-vars,comma-dangle */

      return (
        <LocationAndLocalizationProvider
          locale={locale}
          location={location}
          localizations={localizations}
        >
          <TargetComponent
            locale={locale}
            location={location}
            localizations={localizations}
            {...other}
          />
        </LocationAndLocalizationProvider>
      );
    }
  }

  return ComponentWithContextProviders;
}

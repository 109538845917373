import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { openOneTrustPreferenceCenter } from '../actions/app';
import { loginWithGoogleForDesktop, loginWithOauth } from '../actions/auth';
import { showAvailableDesktopUpdateBanner } from '../actions/desktop';
import { closeNowPlayingDialog } from '../actions/dialog';
import { logClientError } from '../actions/logging';
import {
  pause,
  play,
  retune,
  setShowVolumeBar,
  setVolume,
  stop,
} from '../actions/player';
import { desktopFuncNames } from '../constants/desktop';
import { DESKTOP_MANUAL_UPDATE_VERSIONS } from '../constants/experiments/desktop';
import { ACCOUNT_PATH, SEARCH_PATH } from '../constants/paths';
import { playerStatuses } from '../constants/playerStatuses';
import { LocationAndLocalizationContext } from '../providers/LocationAndLocalizationProvider';
import {
  selectCcpaApplies,
  selectDesktopVersion,
  selectGdprApplies,
  selectPartnerId,
  selectTuneInUserSerial,
} from '../selectors/app';
import {
  selectExperiment,
  selectIsDesktopNowPlayingEnabled,
} from '../selectors/config';
import { selectIsNowPlayingDialogOpen } from '../selectors/dialog';
import invokeDesktopFunction from '../utils/desktop/invokeDesktopFunction';
import isDesktop from '../utils/desktop/isDesktop';
import { syncedPlayerActions } from './connectWithPlayer';

// exported for testing
export function mapStateToProps(state) {
  const { player } = state;
  const { playerStatus, canScrub, volume } = player;
  const desktopVersion = selectDesktopVersion(state);
  const desktopManualUpdateVersions = selectExperiment(
    state,
    DESKTOP_MANUAL_UPDATE_VERSIONS,
  );

  return {
    playerStatus,
    canScrub,
    volume,
    tuneInUserSerial: selectTuneInUserSerial(state),
    partnerId: selectPartnerId(state),
    shouldShowCcpaOptOut: selectCcpaApplies(state),
    isDesktopNowPlayingEnabled: selectIsDesktopNowPlayingEnabled(state),
    isNowPlayingDialogOpen: selectIsNowPlayingDialogOpen(state),
    shouldShowOneTrustGdprOptOut: selectGdprApplies(state) || false,
    showManualUpdateBanner:
      desktopManualUpdateVersions?.includes(desktopVersion) || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    desktopActions: bindActionCreators(
      {
        loginWithOauth,
        loginWithGoogleForDesktop,
        setVolume,
        pause,
        play,
        retune,
        stop,
        setShowVolumeBar,
        logClientError,
        showAvailableDesktopUpdateBanner,
        openOneTrustPreferenceCenter,
        closeNowPlayingDialog,
      },
      dispatch,
    ),
  };
}

/**
 * withDesktopApp decorator implements initialization and updates for the
 * desktop handlers.
 *
 * On the client, we know that the environment is the desktop app because
 * the electron preload script adds a `Bridge` property on the window object
 * which allow us to expose handlers to desktop events
 *
 * The way we check the environment on the server is to pass through a
 * query param (`dsktp=true`) only when loading via electron.
 * This is used to identify the correct partnerId for web vs. desktop
 * and it's passed through here as an additional verification when
 * initializing the `isDesktop` function.
 */
export default function withDesktopApp(TargetComponent) {
  class ComponentWithDesktop extends Component {
    static propTypes = {
      desktopActions: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
      playerStatus: PropTypes.string.isRequired,
      volume: PropTypes.number.isRequired,
      partnerId: PropTypes.string,
      tuneInUserSerial: PropTypes.string,
      canScrub: PropTypes.bool,
      shouldShowCcpaOptOut: PropTypes.bool,
      shouldShowOneTrustGdprOptOut: PropTypes.bool.isRequired,
      isDesktopNowPlayingEnabled: PropTypes.bool.isRequired,
      isNowPlayingDialogOpen: PropTypes.bool.isRequired,
    };

    static contextType = LocationAndLocalizationContext;

    constructor(props, context) {
      super(props, context);

      // it's important to use the isDesktop util in this file (instead of app state);
      // we want the client side verification to guard window.Bridge functions
      if (isDesktop()) {
        // expose the APIS to be triggered by electron
        this.handleDesktopStore();
        this.setDesktopStaticHandlers();
        this.setPlayerVolumeHandlers();

        // invoke event to allow desktop to access localizations
        invokeDesktopFunction(desktopFuncNames.setUpLocalizations);

        // invoke event to allow desktop to communicate by invoking logClientError
        // Important: invoke setUpGeminiEventSender after setUpLocalizations
        // to ensure localizations are set before they are utilized in gemini-desktop
        invokeDesktopFunction(desktopFuncNames.setUpGeminiEventSender);

        // invoke event to allow desktop app to display CCPA opt out page link in Help menu
        // Important: invoke showOptOutPageMenuItem after setUpGeminiEventSender,
        // as that's where the event listener for this call is added
        // Note: unable to change `showOptOutPageMenuItem` to `showCcpaOptOutPageMenuItem` without
        // breaking this functionality in older versions of gemini-desktop.
        if (props.shouldShowCcpaOptOut) {
          invokeDesktopFunction(desktopFuncNames.showOptOutPageMenuItem);
        } else {
          invokeDesktopFunction(desktopFuncNames.hideOptOutPageMenuItem);
        }

        // invoke event to allow desktop app to display GDPR settings link in Help menu
        // Important: invoke showOptOutPageMenuItem after setUpGeminiEventSender,
        // as that's where the event listener for this call is added
        if (props.shouldShowOneTrustGdprOptOut) {
          invokeDesktopFunction(desktopFuncNames.showGdprSettingsMenuItem);
        } else {
          invokeDesktopFunction(desktopFuncNames.hideGdprSettingsMenuItem);
        }

        if (props.showManualUpdateBanner) {
          props.desktopActions.showAvailableDesktopUpdateBanner(true);
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (isDesktop()) {
        const { playerStatus, volume, shouldShowOneTrustGdprOptOut } =
          this.props;

        // update dynamic listeners
        if (prevProps.playerStatus !== playerStatus) {
          this.setPlayerStatusHandlers(prevProps);
        }
        if (prevProps.volume !== volume) {
          this.setPlayerVolumeHandlers();
        }
        if (
          !prevProps.shouldShowOneTrustGdprOptOut &&
          shouldShowOneTrustGdprOptOut
        ) {
          invokeDesktopFunction(desktopFuncNames.showGdprSettingsMenuItem);
        }
      }
    }

    /*
      Handlers that only need to be set once and
      do not require updates based on gemini-web data
    */
    setDesktopStaticHandlers() {
      const { history, desktopActions } = this.props;

      window.Bridge.handleDesktopHistoryFwd = () => {
        const { isDesktopNowPlayingEnabled, isNowPlayingDialogOpen } =
          this.props;
        if (isDesktopNowPlayingEnabled && isNowPlayingDialogOpen) {
          // Empty return here to ensure no confusing forwarding of history behind the NowPlayingDialog if open
          return;
        }
        history.goForward();
      };

      window.Bridge.handleDesktopHistoryBack = () => {
        const { isDesktopNowPlayingEnabled, isNowPlayingDialogOpen } =
          this.props;
        if (isDesktopNowPlayingEnabled && isNowPlayingDialogOpen) {
          this.props.desktopActions.closeNowPlayingDialog();
          return;
        }
        history.goBack();
      };

      window.Bridge.handleNavigateToAccount = () => history.push(ACCOUNT_PATH);

      window.Bridge.handleNavigateToOptOutPage = () =>
        desktopActions.openOneTrustPreferenceCenter();

      window.Bridge.handleOpenGdprSettings = () =>
        desktopActions.openOneTrustPreferenceCenter();

      window.Bridge.handleDesktopSearch = () => history.push(SEARCH_PATH);

      window.Bridge.logClientError = (logData) =>
        desktopActions.logClientError(logData);

      window.Bridge.handleAvailableDesktopUpdate = () => {
        desktopActions.showAvailableDesktopUpdateBanner();
      };

      window.Bridge.handleGoogleDesktopAuth = async (token) => {
        await desktopActions.loginWithGoogleForDesktop(token);
        history.replace({
          ...window.location,
          // NOTE: Needed for the reloadOnPropsChange check in `client/Root.js`
          state: {
            authenticated: true,
          },
        });
      };

      window.Bridge.getLocalizedText = this.context.getLocalizedText;
    }

    setPlayerStatusHandlers(prevProps) {
      const { desktopActions, canScrub, playerStatus } = this.props;

      if (prevProps.playerStatus === playerStatuses.idle) {
        invokeDesktopFunction(desktopFuncNames.enableMenuPlayPause);
      }

      window.Bridge.handleDesktopPlayToggle = () =>
        syncedPlayerActions[playerStatus](desktopActions, canScrub);
    }

    setDesktopVolume(newVolume) {
      const { desktopActions } = this.props;

      desktopActions.setVolume(newVolume);
      desktopActions.setShowVolumeBar(true);
      setTimeout(() => desktopActions.setShowVolumeBar(false), 1500);
    }

    setPlayerVolumeHandlers() {
      const { volume } = this.props;
      const VOLUME_INCREMENT = 10;

      window.Bridge.handleDesktopVolumeUp = () => {
        const newVolume =
          volume <= 100 - VOLUME_INCREMENT ? volume + VOLUME_INCREMENT : 100;

        this.setDesktopVolume(newVolume);
      };

      window.Bridge.handleDesktopVolumeDown = () => {
        const newVolume =
          volume >= VOLUME_INCREMENT ? volume - VOLUME_INCREMENT : 0;

        this.setDesktopVolume(newVolume);
      };
    }

    handleDesktopStore() {
      const { tuneInUserSerial, partnerId } = this.props;
      const serialFromDesktop = invokeDesktopFunction(
        desktopFuncNames.getSerial,
      );
      // if there is no serial saved on the desktop
      if (!serialFromDesktop && tuneInUserSerial) {
        // set the rtid in the desktop store
        invokeDesktopFunction(desktopFuncNames.setSerial, tuneInUserSerial);
      }

      invokeDesktopFunction(desktopFuncNames.setPartnerId, partnerId);
    }

    render() {
      /* eslint-disable no-unused-vars,comma-dangle */
      const {
        playerStatus,
        canScrub,
        volume,
        tuneInUserSerial,
        desktopActions,
        ...other
      } = this.props;
      /* eslint-enable no-unused-vars,comma-dangle */
      return <TargetComponent {...other} />;
    }
  }

  return flow(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
  )(ComponentWithDesktop);
}

export const adTypes = {
  AD_TYPE_UNSPECIFIED: 'AD_TYPE_UNSPECIFIED',
  AD_TYPE_AUDIO: 'AD_TYPE_AUDIO',
  AD_TYPE_VIDEO: 'AD_TYPE_VIDEO',
  AD_TYPE_AUDIO_VIDEO: 'AD_TYPE_AUDIO_VIDEO',
  AD_TYPE_DISPLAY: 'AD_TYPE_DISPLAY',
};

export const adTypesMap = {
  audio: adTypes.AD_TYPE_AUDIO,
  audio_video: adTypes.AD_TYPE_AUDIO_VIDEO,
  video: adTypes.AD_TYPE_VIDEO,
  display: adTypes.AD_TYPE_DISPLAY,
};

import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { Component } from 'react';
import EventListener, { withOptions } from 'react-event-listener';

export default function withScrollPosition(options = {}) {
  const {
    verticalBreakpointOne = 100, // when scrolled past height of header, reposition
    verticalBreakpointTwo = 350, // setup pre-transition by turning opacity back to 1
    verticalBreakpointThree = 675, // position animate in/out
  } = options; // scrollTop positions

  return (MyComponent) => {
    class WithScrollPosition extends Component {
      constructor(props) {
        super(props);

        this.state = this.getDefaultState();

        this.handleScroll = throttle(this.handleScroll.bind(this), 100, {
          leading: false,
        });
        this.toggleScrollListener = this.toggleScrollListener.bind(this);
      }

      getDefaultState() {
        return {
          positionOne: false,
          positionTwo: false,
          positionThree: false,
          offset: null,
          isScrollListenerEnabled: true,
        };
      }

      handleScroll(e) {
        if (!this.state.isScrollListenerEnabled) {
          return;
        }

        const scrollPosition = get(e, 'target.scrollTop');
        const nextState = this.getDefaultState();

        if (scrollPosition < verticalBreakpointOne) {
          nextState.offset = scrollPosition; // we only use offset for moving the header
        } else {
          nextState.positionOne = true; // if the header is out of sight, hide the sticky header

          if (scrollPosition > verticalBreakpointTwo) {
            nextState.positionTwo = true; // turn opacity on so can animate
          }

          if (scrollPosition > verticalBreakpointThree) {
            nextState.positionThree = true; // animate in
          }
        }

        this.setState(nextState);
      }

      toggleScrollListener(enableScrollListener) {
        this.setState({ isScrollListenerEnabled: enableScrollListener });
      }

      render() {
        // eslint-disable-next-line no-unused-vars
        const { isScrollListenerEnabled, ...state } = this.state;

        return (
          <EventListener
            target="window"
            onScroll={withOptions(this.handleScroll, {
              passive: false,
              capture: true,
            })}
          >
            <MyComponent
              {...this.props}
              {...state}
              toggleScrollListener={this.toggleScrollListener}
            />
          </EventListener>
        );
      }
    }

    return WithScrollPosition;
  };
}

import { useSelector } from 'react-redux';
import { BROWSIES_DEFAULT_LANDING_PAGE_INDEX } from '../constants/experiments/config';
import { selectBrowsiesCategory } from '../selectors/categories';
import useExperiment from './useExperiment';

// Browsies is a list of nav pill buttons at the top of the screen. This hook takes an index number from a Config Setting
// to pull the path from the Browsies list that should be rendered as the default landing page on app load.
// Example:
//   When BROWSIES_DEFAULT_LANDING_PAGE_INDEX is 1
//   and Browsies is [{ path: '/path1' }, { path: '/path2' }, { path: '/path3' }],
//   this hook will return '/path2'
export function useBrowsiesDefaultLandingPage() {
  const browsiesDefaultLandingPageIndex = useExperiment(
    BROWSIES_DEFAULT_LANDING_PAGE_INDEX,
  );
  const browsiesGuideItem = useSelector(selectBrowsiesCategory);

  return browsiesGuideItem?.containerItems?.[browsiesDefaultLandingPageIndex]
    ?.path;
}

export const reportingConfigMap = {
  adsPlaybackFailed: {
    endpoint: 'reportAdsPlaybackFailedEvent',
    eventName: 'ADS_PLAYBACK_FAILED',
  },
  adsPlaybackStarted: {
    endpoint: 'reportAdsPlaybackStartedEvent',
    eventName: 'ADS_PLAYBACK_STARTED',
  },
  adsPlaybackFinished: {
    endpoint: 'reportAdsPlaybackFinishedEvent',
    eventName: 'ADS_PLAYBACK_FINISHED',
  },
  contentImpression: {
    endpoint: 'reportContentImpressionEvent',
    eventName: 'CONTENT_IMPRESSION',
  },
  contentClicked: {
    endpoint: 'reportContentClickedEvent',
    eventName: 'CONTENT_CLICKED',
  },
  userPlayClicked: {
    endpoint: 'reportUserPlayClickedEvent',
    eventName: 'USER_PLAY_CLICKED',
  },
  listenSessionStarted: {
    endpoint: 'reportListenSessionStartedEvent',
    eventName: 'LISTEN_SESSION_STARTED',
  },
  applicationSessionStarted: {
    endpoint: 'reportApplicationSessionStartedEvent',
    eventName: 'APP_SESSION_STARTED',
  },
  adsVideoAudioRollRequested: {
    endpoint: 'reportAdsVideoAudioRollRequestedEvent',
    eventName: 'ADS_VIDEO_AUDIO_ROLL_REQUESTED',
  },
  adsVideoAudioRollRequestFailed: {
    endpoint: 'reportAdsVideoAudioRollRequestFailedEvent',
    eventName: 'ADS_VIDEO_AUDIO_ROLL_REQUEST_FAILED',
  },
  adsVideoAudioRollResponseReceived: {
    endpoint: 'reportAdsVideoAudioRollResponseReceivedEvent',
    eventName: 'ADS_VIDEO_AUDIO_ROLL_RESPONSE_RECEIVED',
  },
  adsVideoAudioRollEligibilityDecided: {
    endpoint: 'reportAdsVideoAudioRollEligibilityDecidedEvent',
    eventName: 'ADS_VIDEO_AUDIO_ROLL_ELIGIBILITY_DECIDED',
  },
  adsDisplayRequested: {
    endpoint: 'reportAdsDisplayRequestedEvent',
    eventName: 'ADS_DISPLAY_REQUESTED',
  },
  adsDisplayResponseReceived: {
    endpoint: 'reportAdsDisplayResponseReceivedEvent',
    eventName: 'ADS_DISPLAY_RESPONSE_RECEIVED',
  },
  adsDisplayRequestFailed: {
    endpoint: 'reportAdsDisplayRequestFailedEvent',
    eventName: 'ADS_DISPLAY_REQUEST_FAILED',
  },
  adsDisplayImpression: {
    endpoint: 'reportAdsDisplayImpressionEvent',
    eventName: 'ADS_DISPLAY_IMPRESSION',
  },
  adsDisplayViewabilityStatus: {
    endpoint: 'reportAdsDisplayViewabilityStatusEvent',
    eventName: 'ADS_DISPLAY_VIEWABILITY_STATUS',
  },
  adsDisplayClicked: {
    endpoint: 'reportAdsDisplayClickedEvent',
    eventName: 'ADS_DISPLAY_CLICKED',
  },
  adsInstreamReceived: {
    endpoint: 'reportAdsInstreamReceivedEvent',
    eventName: 'ADS_INSTREAM_RECEIVED',
  },
  adsInstreamStarted: {
    endpoint: 'reportAdsInstreamStartedEvent',
    eventName: 'ADS_INSTREAM_STARTED',
  },
  adsInstreamQuartileStatus: {
    endpoint: 'reportAdsInstreamQuartileStatusEvent',
    eventName: 'ADS_INSTREAM_QUARTILE_STATUS',
  },
  adsInstreamCompleted: {
    endpoint: 'reportAdsInstreamCompletedEvent',
    eventName: 'ADS_INSTREAM_COMPLETED',
  },
  discordOauthAccepted: {
    endpoint: 'reportDiscordOauthAcceptedEvent',
    eventName: 'DISCORD_OAUTH_ACCEPTED',
  },
  discordOauthDenied: {
    endpoint: 'reportDiscordOauthDeniedEvent',
    eventName: 'DISCORD_OAUTH_DENIED',
  },
  discordActivityInitiated: {
    endpoint: 'reportDiscordActivityInitiatedEvent',
    eventName: 'DISCORD_ACTIVITY_INITIATED',
  },
  discordActivityEnded: {
    endpoint: 'reportDiscordActivityEndedEvent',
    eventName: 'DISCORD_ACTIVITY_ENDED',
  },
  discordHostAssigned: {
    endpoint: 'reportDiscordHostAssignedEvent',
    eventName: 'DISCORD_HOST_ASSIGNED',
  },
  discordSharedModeActivated: {
    endpoint: 'reportDiscordSharedModeActivatedEvent',
    eventName: 'DISCORD_SHARED_MODE_ACTIVATED',
  },
  discordSharedModeDeactivated: {
    endpoint: 'reportDiscordSharedModeDeactivatedEvent',
    eventName: 'DISCORD_SHARED_MODE_DEACTIVATED',
  },
  discordUserJoined: {
    endpoint: 'reportDiscordUserJoinedEvent',
    eventName: 'DISCORD_USER_JOINED',
  },
  discordUserLeft: {
    endpoint: 'reportDiscordUserLeftEvent',
    eventName: 'DISCORD_USER_LEFT',
  },
  discordMaxParticipantsReached: {
    endpoint: 'reportDiscordMaxParticipantsReachedEvent',
    eventName: 'DISCORD_MAX_PARTICIPANTS_REACHED',
  },
  sandbox: {
    endpoint: 'reportSandboxEvent',
    eventName: 'SANDBOX_EVENT',
  },
};

import url from 'url';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { Component } from 'react';
import getLoginLocationDescriptor from '../../utils/auth/getLoginLocationDescriptor';
import connectWithAuth from './connectWithAuth';

export default function withAuth(DecoratedComponent) {
  class WithAuth extends Component {
    static propTypes = {
      authActions: PropTypes.shape({
        needsSignInDialog: PropTypes.func.isRequired,
      }),
      loginDetails: PropTypes.shape({
        actionNeedingAuth: PropTypes.func,
      }),
      isAuthenticated: PropTypes.bool.isRequired,
      isMobile: PropTypes.bool,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    componentDidUpdate(prevProps) {
      const {
        history,
        location,
        authActions: { needsSignInDialog },
        isMobile,
      } = this.props;

      // If the user is currently choosing an action that requires authentication
      // and hasn't already chosen an action that requires authentication.
      if (
        !isFunction(prevProps.loginDetails.actionNeedingAuth) &&
        isFunction(this.props.loginDetails.actionNeedingAuth)
      ) {
        // And the user is NOT on a mobile device, then open the sign in dialog
        if (!isMobile) {
          needsSignInDialog();
        } else {
          // Otherwise, redirect to the login page
          history.push(url.format(getLoginLocationDescriptor(location)));
        }
      }
    }

    render() {
      /* eslint-disable no-unused-vars */
      // Using a convenient way to only pass non-auth related props via `other`.
      const { authActions, loginDetails, ...other } = this.props;

      return <DecoratedComponent {...other} />;
    }
  }

  return connectWithAuth(WithAuth);
}

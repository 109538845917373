import { useHistory } from 'react-router-dom';
import useOnMount from './useOnMount';

// History's goBack() breaks under certain conditions on the Discord client. This is possibly caused by bugs in the
// history library.
//
// Known scenarios where goBack() doesn't work:
// 1. goBack() doesn't work on Android.
// 2. The history stack can occasionally be lost when switching between PIP and the activity.

class CustomHistory {
  #stack = [];

  #push;

  get length() {
    return this.#stack.length;
  }

  get pathname() {
    return this.#stack[0];
  }

  init(push) {
    this.#push = push;
  }

  goBack() {
    if (this.length <= 1) {
      return;
    }

    // remove current path
    this.#stack.shift();
    this.#push(this.#stack.shift());
  }

  recordPathChange(pathname) {
    this.#stack.unshift(pathname);
  }
}

export const customHistory = new CustomHistory();

export function useCustomHistory() {
  const history = useHistory();

  useOnMount(() => {
    customHistory.init(history.push);

    const unlisten = history.listen((nextLocation) => {
      if (customHistory.pathname !== nextLocation.pathname) {
        customHistory.recordPathChange(nextLocation.pathname);
      }
    });

    return () => {
      unlisten?.();
    };
  });
}

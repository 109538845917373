import url from 'url';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setShowPairingSuccessPage } from '../../actions/app';
import { selectShowPairingSuccessPage } from '../../selectors/app';
import { selectIsAuthenticated } from '../../selectors/auth';

import { BaseLoader } from '../../components/Loader';
import PairingSuccessLoader from '../../components/auth/pairing/PairingSuccessLoader';

import { hasPairAuthFlowParam } from '../../utils/auth/partnerAuthFlowHelpers';
import { removeCookie } from '../../utils/cookie';
import parseQuery from '../../utils/queryString/parse';

import { showPairingSuccessPageCookie } from '../../constants/cookies';
import { ROOT_PATH } from '../../constants/paths';

export default function withOauthRouteHandling(
  OauthComponent,
  authPath,
  confirmPath,
) {
  class OauthAuthRoute extends Component {
    static propTypes = {
      actions: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      routeProps: PropTypes.object.isRequired,
      showPairingSuccessPage: PropTypes.bool.isRequired,
      auth: PropTypes.object,
    };

    constructor(props) {
      super(props);
      const { location } = props;

      const query = parseQuery(location?.search);
      const isConfirmPath = confirmPath === location?.pathname;
      const sendToPairingFlow = isConfirmPath && hasPairAuthFlowParam(query);
      // NOTE: If !hasPairAuthFlowParam, they go the traditional partner route (i.e. to oauthConfirmation)
      const routeTo = sendToPairingFlow
        ? this.checkForPairingRedirect()
        : this.getOauthRedirect();

      if (routeTo) {
        props.history.replace(routeTo);
      }
      this.state = {
        routeTo,
        sendToPairingFlow,
      };
    }

    componentDidMount() {
      const { actions } = this.props;
      // At this point, it's safe to remove the showPairing cookie and reset redux state
      removeCookie(showPairingSuccessPageCookie.name);
      actions.setShowPairingSuccessPage(false);
    }

    checkForPairingRedirect() {
      const { showPairingSuccessPage } = this.props;

      if (!showPairingSuccessPage) {
        return url.format({
          pathname: ROOT_PATH,
        });
      }

      return null;
    }

    getOauthRedirect() {
      const { auth, location } = this.props;
      const isAuthorizePath = authPath === location?.pathname;
      const isConfirmPath = confirmPath === location?.pathname;
      const isAuthenticatedUser = selectIsAuthenticated({ auth });

      if (!isAuthenticatedUser && isConfirmPath) {
        return url.format({
          ...location,
          pathname: authPath,
        });
      }
      if (isAuthenticatedUser && isAuthorizePath) {
        return url.format({
          ...location,
          pathname: confirmPath,
        });
      }

      return null;
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { auth, location, showPairingSuccessPage, ...otherProps } =
        this.props;
      const { sendToPairingFlow, routeTo } = this.state;
      const componentToRender = sendToPairingFlow ? (
        <PairingSuccessLoader {...otherProps} />
      ) : (
        <OauthComponent {...otherProps} />
      );

      return routeTo ? <BaseLoader /> : componentToRender;
    }
  }

  function mapStateToProps(state) {
    return {
      auth: get(state, 'auth'),
      showPairingSuccessPage: selectShowPairingSuccessPage(state),
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
          setShowPairingSuccessPage,
        },
        dispatch,
      ),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(OauthAuthRoute);
}

import retry from 'async-retry';
import get from 'lodash/get';
import { appError } from '../actions/app';
import { assessAuth } from '../actions/auth';
import isServer from '../utils/isServer';

function enhanceResponse(data) {
  return {
    ...data,
    loadedTime: isServer() ? null : Date.now(),
  };
}

export default function apiClientEffectsMiddleware(
  tuneInApiClient,
  errorCallback,
) {
  return (store) => (next) => (action) => {
    const { type, meta, api } = action;
    let finalAction = action;

    if (api) {
      const payload = new Promise((resolve, reject) => {
        const {
          endpoint,
          args,
          transform,
          registerAppError = true,
          retries = 0,
        } = api;

        const handleAuth = assessAuth(store.dispatch);

        const handleAuthError = (error) => {
          throw handleAuth(error);
        };

        const registerApiError = (err) => {
          // Effects that define `registerAppError=false` will effectively prevent 404/500
          // errors/pages from showing based on the specific api failure response.
          if (registerAppError) {
            store.dispatch(appError(err));
          }

          errorCallback(err, type);

          throw err;
        };

        const apiClientFn = get(tuneInApiClient, endpoint);

        if (!apiClientFn) {
          reject(new Error(`<apiClient>.${endpoint} is not a valid feature`));
        } else {
          const resp = retry(() => apiClientFn(...(args || [])), { retries })
            .catch(registerApiError)
            .then(handleAuth, handleAuthError)
            .then((data) => (transform ? transform(data) : data))
            .then(enhanceResponse);

          resolve(resp);
        }
      });

      finalAction = {
        type,
        meta,
        payload,
      };
    }

    return next(finalAction);
  };
}

import { useHistory } from 'react-router-dom';
import { grantFacebookPixelConsent } from '../../client/analytics/facebook/fbTrack';
import { processGTagEvents } from '../../client/analytics/vendorScripts/loadGTag';
import useLotame from './useLotame';
import useOnConsentReady from './useOnConsentReady';
import useOnMount from './useOnMount';

export default function useClientAnalytics(routeProps, match, onHistoryUpdate) {
  const history = useHistory();

  useOnMount(() => history.listen(onHistoryUpdate));
  useLotame(routeProps, match);
  useOnConsentReady((gdprApplies, isOptedOutOfTargeting) => {
    if (gdprApplies && isOptedOutOfTargeting === false) {
      grantFacebookPixelConsent();
    }

    if (!gdprApplies) {
      processGTagEvents();
    }
  });
}

import keyMirror from 'src/common/utils/keyMirror';

export const playerStatuses = keyMirror(
  'idle',
  'playing',
  'paused',
  'stopped',
  'connecting',
  'failed',
  'popout',
);

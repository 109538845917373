// Map of statuses returned for a coupon lookup from Recurly.
export const statuses = {
  redeemable: 'redeemable', // Represents an 'active' coupon
  inactive: 'inactive',
  expired: 'expired',
  maxedOut: 'maxed_out',
  invalid: 'invalid', // Custom one to represent general invalid lookups (e.g. not found)
};

// Applicable coupon discount types to apply to plans/products.
export const discountTypes = {
  freeTrial: 'free_trial',
  percent: 'percent',
  dollars: 'dollars',
};

// Note: Historically there are places with a strict comparison to the value with 1st letter capitlized, for instance in the automotive flow.
export const trialUnitTypes = {
  month: 'Month',
  day: 'Day',
};

export const stripeDurationTypes = {
  once: 'once',
  forever: 'forever',
  repeating: 'repeating',
};

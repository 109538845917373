import flow from 'lodash/flow';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setBranchShowingStatus, updateBranchLink } from '../actions/app';
import { openNowPlayingDialog } from '../actions/dialog';
import { logMobileWebActivity } from '../actions/logging';
import { playCampaign } from '../constants/analytics/branch';
import mobileWeb from '../constants/analytics/categoryActionLabel/mobileWeb';
import { AB_TEST_IDS } from '../constants/experiments';
import {
  MW_LANDING_DIRECT_TO_NOW_PLAYING,
  MW_LANDING_DISABLED,
  MW_LANDING_DISABLED_GUIDEIDS,
  MW_LANDING_LANDINGONLY_DISABLED,
} from '../constants/experiments/mobileLanding';
import { PLAY_OPEN_IN_APP_ENABLED } from '../constants/experiments/redirect';
import {
  selectIsBot,
  selectIsBranchInitialized,
  selectIsDiscord,
  selectIsMobile,
  selectMwLandingBypassCookieVal,
  selectTuneInUserSerial,
} from '../selectors/app';
import { selectExperiment } from '../selectors/config';
import { getSelectProfileClassification } from '../selectors/profiles';
import {
  addBranchListener,
  closeBranchJourney,
  createBranchLink,
  isBlocklistedBranchRoute,
  removeBranchListener,
  reopenBranchJourney,
  setBranchViewData,
} from '../utils/branch/branchHelper';
import didRouteChange from '../utils/didRouteChange';
import gtagTrack from '../utils/gtagTrack';
import { isProfile } from '../utils/guideItemTypes';
import isServer from '../utils/isServer';
import connectWithExperiments from './connectWithExperiments';

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openNowPlayingDialog,
        setBranchShowingStatus,
        updateBranchLink,
        logMobileWebActivity,
      },
      dispatch,
    ),
  };
}

export function mapStateToProps(state, ownProps) {
  return {
    isMobile: selectIsMobile(state),
    tuneInUserSerial: selectTuneInUserSerial(state),
    abTestIds: selectExperiment(state, AB_TEST_IDS),
    mwLandingBypassCookieVal: selectMwLandingBypassCookieVal(state),
    isBranchInitialized: selectIsBranchInitialized(state),
    isBot: selectIsBot(state),
    isDiscord: selectIsDiscord(state),
    rootGenreClassification: getSelectProfileClassification(
      ownProps.routeProps?.guideContext?.guideId,
    )(state)?.rootGenreClassification,
  };
}

export default function withMobileJourney(TargetComponent) {
  class WithMobileJourney extends Component {
    static propTypes = {
      actions: PropTypes.object.isRequired,
      routeProps: PropTypes.object.isRequired,
      tuneInUserSerial: PropTypes.string.isRequired,
      isMobile: PropTypes.bool.isRequired,
      isDiscord: PropTypes.bool.isRequired,
      abTestIds: PropTypes.string,
      mwLandingBypassCookieVal: PropTypes.bool.isRequired,
      isBranchInitialized: PropTypes.bool.isRequired,
      [MW_LANDING_LANDINGONLY_DISABLED]: PropTypes.bool,
      [MW_LANDING_DIRECT_TO_NOW_PLAYING]: PropTypes.bool,
      [MW_LANDING_DISABLED_GUIDEIDS]: PropTypes.string,
      [MW_LANDING_DISABLED]: PropTypes.bool,
      [PLAY_OPEN_IN_APP_ENABLED]: PropTypes.bool,
      isBot: PropTypes.bool.isRequired,
      rootGenreClassification: PropTypes.string,
    };

    constructor(props) {
      super(props);

      const {
        actions,
        [MW_LANDING_DIRECT_TO_NOW_PLAYING]: mwLandingDirectToNowPlayingTest,
        routeProps,
        isMobile,
        isDiscord,
        isBranchInitialized,
      } = this.props;

      if (isServer()) {
        const guideId = routeProps?.guideContext?.guideId;
        if (
          mwLandingDirectToNowPlayingTest &&
          isMobile &&
          isProfile(guideId) &&
          !isDiscord
        ) {
          actions.openNowPlayingDialog(guideId);
        }
      }

      this.areBranchListenersRegistered = false;

      if (
        isBranchInitialized &&
        !isBlocklistedBranchRoute(routeProps?.matchUrl) &&
        !isDiscord
      ) {
        this.openBranchJourney();
      }
    }

    componentWillUnmount() {
      this.removeBranchListeners();
    }

    componentDidUpdate(prevProps) {
      const { isBranchInitialized, routeProps } = this.props;
      const matchUrl = routeProps?.matchUrl;

      if (
        isBranchInitialized &&
        !prevProps.isBranchInitialized &&
        !isBlocklistedBranchRoute(matchUrl)
      ) {
        this.openBranchJourney();
        return;
      }

      if (isBranchInitialized && didRouteChange(prevProps, this.props)) {
        const currentRoute = matchUrl;
        const prevRoute = prevProps?.routeProps?.matchUrl;
        const isCurrentRouteBlocklisted =
          isBlocklistedBranchRoute(currentRoute);
        const isPrevRouteBlocklisted = isBlocklistedBranchRoute(prevRoute);

        // if navigating to an allowlisted page from an allowlisted page, restart branch journey
        if (!isCurrentRouteBlocklisted && !isPrevRouteBlocklisted) {
          this.updateBranchJourney(this.closeBranchJourney, true);

          // if navigating to an allowlisted page from a blocklisted page, open branch journey
        } else if (!isCurrentRouteBlocklisted && isPrevRouteBlocklisted) {
          this.updateBranchJourney(this.openBranchJourney);

          // if navigating to a blocklisted page from an allowlisted page, close branch journey
        } else if (!isPrevRouteBlocklisted) {
          this.updateBranchJourney(this.closeBranchJourney);
        }
      }
    }

    setBranchUrl = (branchUrl) => {
      this.props.actions.updateBranchLink(branchUrl);
    };

    updateBranchJourney(action, shouldRestartBranchJourney = false) {
      this.shouldRestartBranchJourney = shouldRestartBranchJourney;
      action();
    }

    openBranchJourney = () => {
      const {
        tuneInUserSerial,
        abTestIds,
        routeProps,
        rootGenreClassification,
        [PLAY_OPEN_IN_APP_ENABLED]: playRedirectToAppStoreTest,
      } = this.props;
      const { guideId, title } = routeProps?.guideContext || {};
      const customBranchCampaign = playRedirectToAppStoreTest
        ? playCampaign
        : undefined;
      const options = {
        guideId,
        title,
        serial: tuneInUserSerial,
        abTestIds,
        customCampaign: customBranchCampaign,
        rootGenreClassification,
      };

      if (!this.areBranchListenersRegistered) {
        this.addBranchListeners();
      }

      setBranchViewData(options);
      reopenBranchJourney();
      createBranchLink(options, this.setBranchUrl);
    };

    closeBranchJourney = () => {
      this.removeBranchListeners();
      closeBranchJourney();
    };

    onDidShowJourney = () => {
      this.props.actions.setBranchShowingStatus(true);
    };

    onDidCloseJourney = () => {
      this.props.actions.setBranchShowingStatus(false);

      if (this.shouldRestartBranchJourney) {
        this.updateBranchJourney(this.openBranchJourney);
      }
    };

    onDidClickJourneyCta = () => {
      const { actions } = this.props;
      gtagTrack.branchInstallClick();
      actions.logMobileWebActivity(
        mobileWeb.actions.tap,
        mobileWeb.labels.branchBannerAppDownload,
      );
    };

    addBranchListeners() {
      this.areBranchListenersRegistered = true;
      addBranchListener('didShowJourney', this.onDidShowJourney);
      addBranchListener('didCloseJourney', this.onDidCloseJourney);
      addBranchListener('didClickJourneyCTA', this.onDidClickJourneyCta);
    }

    removeBranchListeners() {
      this.areBranchListenersRegistered = false;
      removeBranchListener(this.onDidShowJourney);
      removeBranchListener(this.onDidCloseJourney);
      removeBranchListener(this.onDidClickJourneyCta);
    }

    render() {
      let shouldShowMobileOverlay = false;

      const {
        [MW_LANDING_DIRECT_TO_NOW_PLAYING]: mwLandingDirectToNowPlayingTest,
        [MW_LANDING_DISABLED]: mwLandingDisabledTest,
        [MW_LANDING_DISABLED_GUIDEIDS]: mwLandingDisabledGuideIdsTest,
        [MW_LANDING_LANDINGONLY_DISABLED]: mwLandingLandingOnlyEnabledTest,
        mwLandingBypassCookieVal, // mobile overlay turned off by cookie
        isMobile,
        routeProps,

        // destructuring props below to prevent passing to target component
        /* eslint-disable no-unused-vars */
        [PLAY_OPEN_IN_APP_ENABLED]: playRedirectToAppStoreTest,
        tuneInUserSerial,
        abTestIds,
        actions,
        isBot,
        isDiscord,
        /* eslint-enable no-unused-vars */

        ...other // eslint-disable-line comma-dangle
      } = this.props;

      if (isMobile && !isDiscord) {
        const mwLandingDisabledGuideIds = mwLandingDisabledGuideIdsTest
          ? mwLandingDisabledGuideIdsTest.split(',')
          : '';

        const { guideId } = routeProps.guideContext;

        // overlay disabled by cookie or tests
        const disableMWOverlay =
          isBot ||
          mwLandingBypassCookieVal || // remembered by cookie
          // mobile overlay turned off
          mwLandingDisabledTest ||
          // mobile overlay turned off for current guideId
          includes(mwLandingDisabledGuideIds, guideId) ||
          // if ⬇️ === true, mobile overlay suppressed in lieu of nowPlayingDialog
          (isProfile(guideId) && mwLandingDirectToNowPlayingTest) ||
          // enabled ONLY for the homepage
          (routeProps.isLanding && mwLandingLandingOnlyEnabledTest);

        // overlay enabled by
        shouldShowMobileOverlay = !!(
          (
            routeProps.isOverlayablePage && // determined by route
            !disableMWOverlay
          ) // and whenever not disabled
        );
      }

      return (
        <TargetComponent
          {...other}
          routeProps={routeProps}
          shouldShowMobileOverlay={shouldShowMobileOverlay}
        />
      );
    }
  }

  return flow(
    connect(mapStateToProps, mapDispatchToProps),
    connectWithExperiments([
      MW_LANDING_LANDINGONLY_DISABLED,
      MW_LANDING_DIRECT_TO_NOW_PLAYING,
      MW_LANDING_DISABLED,
      MW_LANDING_DISABLED_GUIDEIDS,
      PLAY_OPEN_IN_APP_ENABLED,
    ]),
  )(WithMobileJourney);
}

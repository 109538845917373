import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-config';
import { createRadioBrowseContext } from '../routes/utils/getBrowseContext';
import { createRadioProfileContext } from '../routes/utils/getProfileContext';
import { selectIsDiscord, selectIsFord } from '../selectors/app';

const routePropsOverrides = {
  discord: {
    isDiscord: true,
    hideLeft: true,
    hideRight: true,
    showNowPlayingAndVideoAdDialogsOnly: true,
    noHeader: true, // Note: this is for the web header, so showBrowsiesHeader will apply independently
    showBrowsiesHeader: true,
    hideAds: true,
  },
  ford: {
    isFord: true,
  },
};

export default function withCurrentRouteProps(WrappedComponent) {
  class WithCurrentRouteInfo extends Component {
    shouldComponentUpdate({ location: nextLocation = {} }) {
      const { pathname, search } = this.props?.location || {};

      return (
        pathname !== nextLocation?.pathname || search !== nextLocation?.search
      );
    }

    render() {
      const { location, route, isDiscord, isFord } = this.props;
      const matchedRoutes = matchRoutes(route.routes, location.pathname);
      const currentRoute = matchedRoutes[0] || {};
      const routeLevelProps = currentRoute?.route?.routeLevelProps || {};

      // route props needed before the component has mounted
      const customRouteProps = {
        ...routeLevelProps,
        ...(isDiscord && routePropsOverrides.discord),
        ...(isFord && routePropsOverrides.ford),
      };

      /*
        `match` seems to only be reliable on the route level and should be
        used wherever data needs to be fetched before rendering.

        matchUrl is set here specifically for the didRouteChange util because
        the match prop does not update consistently in nested components.
      */
      customRouteProps.matchUrl = currentRoute?.match?.url; // for didRouteChange

      // get title, guideId because we used to rely on those in the params property on the route
      customRouteProps.guideContext =
        createRadioProfileContext({ location, ...currentRoute }) ||
        createRadioBrowseContext({ location, ...currentRoute }) ||
        {};

      customRouteProps.guideContext.itemToken = location?.state?.itemToken;

      return <WrappedComponent {...this.props} routeProps={customRouteProps} />;
    }
  }

  WithCurrentRouteInfo.propTypes = {
    location: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
  };

  return connect(mapStateToProps)(WithCurrentRouteInfo);
}

function mapStateToProps(state) {
  return {
    isDiscord: selectIsDiscord(state),
    isFord: selectIsFord(state),
  };
}

export const reportingAdSlotNames = {
  AD_SLOT_UNSPECIFIED: 'AD_SLOT_UNSPECIFIED',
  AD_SLOT_PREROLL: 'AD_SLOT_PREROLL',
  AD_SLOT_MIDROLL: 'AD_SLOT_MIDROLL',
  AD_SLOT_DISPLAY: 'AD_SLOT_DISPLAY',
  AD_SLOT_INSTREAM: 'AD_SLOT_INSTREAM',
};

export const adSlotNameMap = {
  midroll: reportingAdSlotNames.AD_SLOT_MIDROLL,
  preroll: reportingAdSlotNames.AD_SLOT_PREROLL,
  display: reportingAdSlotNames.AD_SLOT_DISPLAY,
  instream: reportingAdSlotNames.AD_SLOT_INSTREAM,
};

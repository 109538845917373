/* eslint-disable max-len */
import {
  ADDRESS_NOT_A_MATCH,
  AVS_CHECK_FAILED,
  CARD_DECLINED,
  CARD_ISSUER_CARDHOLDER_DATA,
  CARD_IS_EXPIRED,
  CARD_NOT_ACTIVATED,
  CARD_NUMBER_INVALID,
  CARD_TYPE_NOT_ACCEPTED,
  CVV_NOT_MATCH,
  CVV_REQUIRED,
  DECLINED_BY_BANK,
  DECLINED_CARD_NUMBER,
  DECLINED_EXPIRATION_DATE,
  DECLINED_SECURITY_CODE,
  DUPLICATE_TRANSACTION,
  EXCEEDS_DAILY_LIMIT,
  EXISTING_SUBSCRIPTION_ERROR,
  FRAUD_ADVANCED_VERIFICATION,
  FRAUD_GENERIC,
  FRAUD_MANUAL_DECISION,
  FRAUD_RISK_CHECK,
  FRAUD_STOLEN_CARD,
  FRAUD_TOO_MANY_ATTEMPTS,
  FRAUD_VELOCITY_ERROR,
  GATEWAY_ERROR,
  GATEWAY_TOKEN_NOT_FOUND,
  INSUFFICIENT_FUNDS,
  INVALID_DATA,
  INVALID_ISSUER,
  INVALID_MERCHANT_TYPE,
  INVALID_TRANSACTION,
  ISSUER_UNAVAILABLE,
  MISSING_REQUIRED_BILLING_INFO,
  NO_GATEWAY,
  PAYMENT_NOT_ACCEPTED,
  PROCESSOR_UNAVAILABLE,
  RESTRICTED_CARD,
  RESTRICTED_CARD_CHARGEBACK,
  TEMPORARY_HOLD,
  TIMEOUT_ERROR,
  TOO_MANY_ATTEMPTS,
  TRANSACTION_DECLINED_1,
  TRANSACTION_DECLINED_2,
  TRANSACTION_DECLINED_3,
  TRANSACTION_DECLINED_EXCEPTION,
  TRY_AGAIN,
} from '../localizations/subscription';

import { ERROR_INVALID as COUPON_INVALID } from '../localizations/coupon';

// Possible transaction error codes: https://dev.recurly.com/page/transaction-errors
export const RecurlyValidationCodes = {
  declined: CARD_DECLINED,
  fraud_address: AVS_CHECK_FAILED,
  fraud_gateway: TRANSACTION_DECLINED_1,
  fraud_ip_address: TRANSACTION_DECLINED_2,
  declined_saveable: TRANSACTION_DECLINED_3,
  declined_expiration_date: DECLINED_EXPIRATION_DATE,
  insufficient_funds: INSUFFICIENT_FUNDS,
  call_issuer: DECLINED_BY_BANK,
  card_type_not_accepted: CARD_TYPE_NOT_ACCEPTED,
  gateway_timeout: TIMEOUT_ERROR,
  duplicate_transaction: DUPLICATE_TRANSACTION,
  invalid_data: INVALID_DATA,
  fraud_security_code: CVV_NOT_MATCH,
  invalid_card_number: CARD_NUMBER_INVALID,
  temporary_hold: TEMPORARY_HOLD,
  fraud_velocity: FRAUD_VELOCITY_ERROR,
  too_many_attempts: TOO_MANY_ATTEMPTS,
  call_issuer_update_cardholder_data: CARD_ISSUER_CARDHOLDER_DATA,
  declined_security_code: DECLINED_SECURITY_CODE,
  declined_exception: TRANSACTION_DECLINED_EXCEPTION,
  declined_missing_data: MISSING_REQUIRED_BILLING_INFO,
  declined_card_number: DECLINED_CARD_NUMBER,
  gateway_token_not_found: GATEWAY_TOKEN_NOT_FOUND,
  expired_card: CARD_IS_EXPIRED,
  exceeds_daily_limit: EXCEEDS_DAILY_LIMIT,
  invalid_merchant_type: INVALID_MERCHANT_TYPE,
  invalid_transaction: INVALID_TRANSACTION,
  invalid_issuer: INVALID_ISSUER,
  payment_not_accepted: PAYMENT_NOT_ACCEPTED,
  restricted_card: RESTRICTED_CARD,
  restricted_card_chargeback: RESTRICTED_CARD_CHARGEBACK,
  card_not_activated: CARD_NOT_ACTIVATED,
  fraud_stolen_card: FRAUD_STOLEN_CARD,
  fraud_too_many_attempts: FRAUD_TOO_MANY_ATTEMPTS,
  fraud_advanced_verification: FRAUD_ADVANCED_VERIFICATION,
  fraud_generic: FRAUD_GENERIC,
  fraud_address_recurly: ADDRESS_NOT_A_MATCH,
  fraud_risk_check: FRAUD_RISK_CHECK,
  fraud_manual_decision: FRAUD_MANUAL_DECISION,
  processor_unavailable: PROCESSOR_UNAVAILABLE,
  issuer_unavailable: ISSUER_UNAVAILABLE,
  gateway_error: GATEWAY_ERROR,
  try_again: TRY_AGAIN,
  cvv_required: CVV_REQUIRED,
  no_gateway: NO_GATEWAY,
  already_subscribed: EXISTING_SUBSCRIPTION_ERROR,
  existing_subscription: EXISTING_SUBSCRIPTION_ERROR,
  invalid: 'invalid',
};

export const RecurlyInvalidFields = {
  'subscription.coupon_code': COUPON_INVALID,
};

const combinedCodes = Object.values(RecurlyValidationCodes).concat(
  Object.values(RecurlyInvalidFields),
);

export default combinedCodes;
